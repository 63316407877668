


































import Vue from 'vue';
import { Component, Prop } from 'nuxt-property-decorator';

import { IconArrowRight } from '@/icons';
import type { ScenkonstOtSubscriptionContent } from '@/graphql/generated';

@Component({
  components: {
    IconArrowRight,
  },
})
export default class BlockSubscriptionListDetails extends Vue {
  @Prop()
  subscription: ScenkonstOtSubscriptionContent;
}
