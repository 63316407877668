










































import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { Swiper, Navigation, Scrollbar, Mousewheel } from 'swiper/js/swiper.esm';

import scss from '@/assets/scss/exports.scss';
import { IconArrowLeft, IconArrowRight } from '@/icons';
Swiper.use([Navigation, Scrollbar, Mousewheel]);

@Component({
  components: {
    IconArrowLeft,
    IconArrowRight,
  },
})
export default class HorizontalScrollSlider extends Vue {
  @Prop({
    type: Object,
    default: () => ({ sm: 3, md: 4, lg: 4, xl: 4 }),
  })
  slidesPerView!: { sm: number, md: number, lg: number, xl: number };

  slider;
  swiperOptions = {};
  mounted () {
    this.setOptions();
    this.slider = new Swiper(this.$refs.swiperContainer as HTMLElement, this.swiperOptions);
    this.slider.init();
  }

  setOptions () {
    this.swiperOptions = {
      slidesPerView: 1.5,
      spaceBetween: 16,
      freeMode: true,
      grabCursor: true,
      watchOverflow: true,
      navigation: {
        prevEl: this.$refs.swiperPrev as HTMLElement,
        nextEl: this.$refs.swiperNext as HTMLElement,
        disabledClass: '-disabled',
      },
      scrollbar: {
        el: this.$refs.swiperScrollbar,
        hide: false,
        draggable: false,
        dragClass: 'horizontal-scroll-slider_scrollbar-progress',
      },
      mousewheel: {
        forceToAxis: true,
      },
      breakpoints: {
        [scss.breakpointsm]: {
          slidesPerView: this.slidesPerView.sm,
          slidesPerGroup: Math.floor(this.slidesPerView.sm - 1),
          spaceBetween: 24,
        },
        [scss.breakpointmd]: {
          slidesPerView: this.slidesPerView.md,
          slidesPerGroup: Math.floor(this.slidesPerView.md - 1),
          spaceBetween: 32,
        },
        [scss.breakpointlg]: {
          slidesPerView: this.slidesPerView.lg,
          slidesPerGroup: Math.floor(this.slidesPerView.lg - 1),
          spaceBetween: 32,
        },
        [scss.breakpointxl]: {
          slidesPerView: this.slidesPerView.xl,
          slidesPerGroup: Math.floor(this.slidesPerView.xl - 1),
          spaceBetween: 32,
        },
      },
    };
  }

  beforeDestroy () {
    setTimeout(this.slider.destroy, 100);
  }
}
