








































import { Component, Vue, Prop } from 'nuxt-property-decorator';

import { IconPlus } from '@/icons';

@Component({
  components: {
    IconPlus,
  },
})
export default class Disclosure extends Vue {
  @Prop(String)
  title!: string;

  @Prop({ type: String, required: true })
  id!: string;

  @Prop(Boolean)
  openFromStart!: boolean

  @Prop(Boolean)
  sm!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  hideButton!: boolean

  active = false;

  created () {
    this.active = this.openFromStart;
  }

  toggleActive () {
    this.active = !this.active;
    this.$emit('active', this.id);
  }
}
