






































import { Component, Prop, Vue } from 'nuxt-property-decorator';

import { IconArrowRight } from '@/icons';

@Component({
  components: {
    IconArrowRight,
  },
})
export default class CardSubscriptionPack extends Vue {
  @Prop({ type: String })
  title?: string;

  @Prop({ type: String })
  subtitle?: string;

  @Prop({ type: String })
  secondaryText?: string;

  @Prop({ type: Object })
  image?: any;

  @Prop({ type: String })
  url?: string;

  @Prop({ type: Boolean, default: false })
  active: boolean

  @Prop({ type: Boolean, default: true })
  showButton: boolean

  @Prop({ type: String })
  htmlTitle?: string

  get aProps () {
    if (!this.url)
      return {};

    if (this.url.startsWith('/'))
      return {
        href: this.url,
        target: '_blank',
      };

    return {
      href: this.url,
      target: '_blank',
      rel: 'noopener',
    };
  }
}
