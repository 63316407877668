







import { Component, Vue, Prop } from 'nuxt-property-decorator';

import { getContrastTextColor } from '../animate/BodyBackground/contrast-utils';

@Component
export default class BlockWrapper extends Vue {
  @Prop({ type: String, default: null })
  backgroundColor!: string

  @Prop({ type: String, default: null })
  textColor!: string

  get blockColorStyle () {
    if (!this.backgroundColor)
      return {};

    return {
      backgroundColor: this.backgroundColor,
      color: this.textColor,
      '--current-color': this.textColor,
      '--current-color-inverted': getContrastTextColor(this.textColor),
    };
  }
}
