











































































































import { gql } from 'graphql-tag';
import { Component, mixins } from 'nuxt-property-decorator';

import { useSubscriptions, SubscriptionPack } from '@/composables/subscriptions';
import { blockableBlockColorFragment, blockColorMixin } from '@/composables/block-color';

import type { ScenkonstSubscriptionsListBlock } from '@/graphql/generated';
import { PageBlockMixin } from '@/modules/cms';
import { ComposableMixin } from '@/mixins/composable';
import { IconArrowRight } from '@/icons';

@Component({
  graphqlData: gql`
    fragment ScenkonstSubscriptionsList on ScenkonstSubscriptionsListBlock {
      heading
      subscriptionPacks
      ...BlockColor
    }
    ${blockableBlockColorFragment}
  `,
  components: {
    IconArrowRight,
  },
})
export default class ScenkonstSubscriptionsList extends mixins(
  PageBlockMixin<ScenkonstSubscriptionsListBlock>(),
  ComposableMixin(useSubscriptions, props => [props.data.subscriptionPacks]),
  blockColorMixin,
) {
  get contentContainerId () {
    return `subscription-packs-content-${this._uid}`;
  }

  async setActiveSubscriptionPack (subscriptionPack: SubscriptionPack) {
    if (this.activeSubscriptionPack !== subscriptionPack) {
      this.activeSubscriptionPack = subscriptionPack;
      await this.$nextTick();
      document.getElementById(this.contentContainerId)?.scrollIntoView({ behavior: 'smooth' });
    } else {
      this.activeSubscriptionPack = null;
    }
  }

  nl2r (str: string) {
    return str.replace(/\n/g, '<br />');
  }
}
