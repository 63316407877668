
























import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component({
  inheritAttrs: false,
  model: {
    prop: 'checked',
    event: 'change',
  },
})
export default class Radio extends Vue {
  @Prop(Boolean)
  checked!: Boolean;

  @Prop(Boolean)
  disabled!: Boolean;

  @Prop(String)
  title!: string;

  get id () {
    return `radio_${this._uid}`;
  }

  get listeners () {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { change, ...rest } = this.$listeners;
    return rest;
  }

  clickradio () {
    (this.$refs.radio as HTMLInputElement).click();
  }
}
