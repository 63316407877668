import { gql } from 'graphql-tag';

import { medium1x1ImageFragment } from './image.fragments';

export const subscriptionPacksQuery = gql`
  query subscriptionPacks ($subscriptionPackIds: [ID!]!) {
    scenkonstOtSubscriptionPacks (subscriptionPackIds: $subscriptionPackIds) {
      id
      title
      subtitle
      secondaryText
      description
      url
      image {
        ...Medium1x1ImageData
      }
    }
  }

  ${medium1x1ImageFragment}
`;

export const subscriptionPackDetailsQuery = gql`
  query subscriptionPackDetails($subscriptionPackId: ID!) {
    scenkonstOtSubscriptionWeekdays(subscriptionPackId: $subscriptionPackId) {
      id
      name
    }
    scenkonstOtSubscriptionPerformanceCategories(subscriptionPackId: $subscriptionPackId) {
      id
      name
    }
    scenkonstOtSubscriptions(subscriptionPackId: $subscriptionPackId) {
      id
      title
      price
      priceInfo
      purchaseUrl
      weekday
      performanceCategory {
        id
      }
      events {
        startDateText
        performance {
          id
          title
          subtitle
          pageUrl
          image {
            ...Medium1x1ImageData
          }
        }
      }
    }
  }

  ${medium1x1ImageFragment}
`;
